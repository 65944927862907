<template>
  <div>
    <div>
      <div class="form-row">
        <div class="form-group input-group col-md-2 mt-3 pr-md-3" style="font-weight: bold;">
          Class Name 
        </div>
        <div class="form-group input-group col-md-6 mt-3 pr-md-3">
          {{ classDetails ? classDetails.className : "" }}
        </div>
      </div>
      <div class="form-row" v-if="section &&  section.name">
        <div class="col-md-2" style="font-weight: bold;">
          Classroom Name
        </div>
        <div class="col-md-6">
          {{ section.name }}
        </div>
      </div>
      <div class="form-row" v-if="section &&  section.name && userDetail.profile.name != 'Teacher'">
        <div class="form-group input-group col-md-2 mt-3 pr-md-3" style="font-weight: bold;">
           Teachers Assigned
        </div>
        <!-- {{ section.assignedTeachers }} -->
        <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3">{{ getAdminName }}</div> -->
        <div class="form-group input-group col-md-4 mt-3 pr-md-3">
          <button v-if="section && section.assignedTeachers && section.assignedTeachers.length > 0"
            style="padding-top: 2px;
    width: 21%;
    height: 26px;"
            type="button"
            class="btn btnsml"
            @click="openAssignTeacherPopUp"
          >
            View
          </button>
          <span v-else>No Teachers Assigned</span>
        </div>
      </div>
      <div class="form-row" v-else>
        <div class="form-group input-group col-md-2 mt-2 pr-md-3" style="font-weight: bold;">
           
        </div>
        <div class="form-group input-group col-md-4 pr-md-3">
         
          
        </div>
      </div>
      <!-- <div class="form-row" v-if="userDetail.profile.name != 'Teacher'">
        <div class="form-group input-group col-md-2 mt-3 pr-md-3">
          Grade Setting
        </div>
        <div class="form-group input-group col-md-4 mt-3 pr-md-3">
          <button
            style="width: 46%"
            type="button"
            class="btn btnsml"
            v-b-modal.gradeSetting
          >
            Grade Setting
          </button>
        </div>
      </div> -->
      <div class="form-row">
        <div class="col-md-2" style="font-weight: bold;">
          
          No. of Students: <a style="font-weight: 600;color: green;" href="javascript:void(0);"  @click.prevent="redirectRouteToViewStudent()"> {{ students.length }} </a>
        </div>
        <!-- <div class="form-group input-group col-md-1 mt-3 pr-md-3">
          {{ students.length }}
        </div> -->
        <div class="col-md-2" style="font-weight: bold;">Boys:  {{ boysCount }}</div>
        <!-- <div class="form-group input-group col-md-1 mt-3 pr-md-3">
          {{ boysCount }}
        </div> -->
        <div class="col-md-2" style="font-weight: bold;">Girls:  {{ girlsCount }}</div>
        <!-- <div class="form-group input-group col-md-1 mt-3 pr-md-3">
          {{ girlsCount }}
        </div> -->
        <div
          class="col-md-2" style="font-weight: bold;"
          v-if="genderNotSelected > 0"
        >
          Others: {{ genderNotSelected }}
        </div>
        <!-- <div
          class="form-group input-group col-md-1 mt-3 pr-md-3"
          v-if="genderNotSelected > 0"
        >
          {{ genderNotSelected }}
        </div> -->

        <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3">
          <button type="button" class="btn btnsml" v-b-modal.gradeSetting>
            Grade Setting
          </button>
        </div> -->
      </div>
    </div>
    <b-modal
      id="assignTeacherPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>ASSIGN TEACHERS</h1>
              </div>
              <div style="margin-top: 10px">
                <!-- <input type="text" placeholder="Search Teacher" /> -->
              </div>
              <div class="mt-1">
                <!-- <button class="btn gry_bg mpopup" v-b-modal.addacademicyr>
                  <i class="fas fa-calendar-plus"></i>
                </button> -->
              </div>
            </div>
            <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Teacher Name</div>
                  <div class="cell">Subject</div>
                  <div class="cell">Is Admin</div>
                </div>

                <div
                  class="resrow"
                  v-for="(data, index) in section.assignedTeachers"
                  :key="index"
                >
                  <div class="cell" data-title="Teacher" v-if="data.teacherId">
                    {{ getTeacherName(data.teacherId) }}
                  </div>
                  <div class="cell" data-title="Subject" >
                          {{ getSubjectName(data.subjectId) }}
                        </div>

                  <div class="cell chkbox" data-title="Select" v-if="data.teacherId">
                    <label class="custcheckbox">
                      <input type="checkbox" disabled v-model="data.isAdmin" />
                      <span
                        class="checkmarkchk"
                        style="color: lightgreen"
                      ></span>
                    </label>
                  </div>

                  <!-- <div class="cell" data-title="Action">
                          <button class="btn round orange tooltipt" :disabled="teacherSubject.isAdmin" @click="removeAssignedObject(index)">
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div> -->
                </div>
              </div>
            </div>
            <div class="widjetfooter text-center dkicon">
              <button
                type="button"
                @click="closeAssignTeacherPopUp"
                class="btn btncl clsmdl"
              >
                   <span>Cancel</span>
              </button>
              <!-- <button type="submit" class="btn btnsml" id="svbtn">
                  <span>Delete</span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="classPerformancePopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>STUDENT WISE</h1>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  Term 1
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select class="form-control form-input" id="deptlistid">
                      <option :value="null">-- Select --</option>
                    </select>
                    <label for="clslistid">Select Academic Year </label>
                  </span>
                </div>
              </div>
            </div>
            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Name</div>
                  <div class="cell">
                    <a href="javascript:void(0);">Academics </a>
                  </div>
                  <div class="cell">
                    <a href="javascript:void(0);">Curricular Activities </a>
                  </div>
                  <div class="cell">
                    <a href="javascript:void(0);">Attendance </a>
                  </div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Name</div>
                  <div class="cell">Term 1</div>
                  <div class="cell">Term 2</div>
                  <div class="cell">Term 3</div>
                  <div class="cell">Term 4</div>
                  <div class="cell">Action</div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeClassPerformancePopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
   
   
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
  },
  data() {
    return {
      userDetail: {},
      students: [],
      subjectList: [],
      classDetails: {},
      section: {},
      rangeFromList: [],
      rangeToList: [],
      gradeMarkList: [],
      gradeOthersList: [],
      gradeAttendanceList: [],
      gradeType: null,
      // gradeTypes: [
      //   "GRADE SETUP",
      //   "Attendance Grading Scale",
      // ],
      gradeSetupDetails: {
        from: "",
        to: "",
        marks: null,
        grade: "",
      },
    };
  },
  watch: {
    classId: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";
      if (hasCurrentParam != hasOldParam) {
        this.getOverViewInfo();
      }
    },

    classRoom: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";
      if (hasCurrentParam != hasOldParam) {
        this.getOverViewInfo();
      }
    },
  },
  created() {
    this.initComponent();
    this.getOverViewInfo();
    this.getSubjectList()

    //this.getGradeSetupDetails();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    boysCount() {
      return this.students.filter((x) => x.gender == "Male").length;
    },
    girlsCount() {
      return this.students.filter((x) => x.gender == "Female").length;
    },
    genderNotSelected() {
      return this.students.filter(
        (x) =>
          x.gender == "" ||
          x.gender == "" ||
          x.gender == undefined ||
          x.gender == null
      ).length;
    },

    getAdminName() {
      if (
        this.section.assignedTeachers &&
        this.section.assignedTeachers.length > 0
      ) {
        let adminTeacherDetails = this.section.assignedTeachers.find(
          (x) => x.isAdmin == true
        );

        if (adminTeacherDetails) {
          let getUserName = this.$store.getters.getUserTeacherList.find(
            (x) => x._id == adminTeacherDetails.teacherId
          );
          if (getUserName) {
            return getUserName.firstName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },

  methods: {
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;

      }
    },
    setTotalRange(data){
      this.rangeFromList = []
      if(data){
      for (var i = 0; i <= data; i++) {
        this.rangeFromList.push(i);
      }

     }
    },
    getTeacherName(teacherId) {
      if (teacherId) {
        const teacherDetails = this.$store.getters.getUserTeacherList.find(
          (x) => x._id == teacherId
        );

        if (teacherDetails) {
          return teacherDetails.firstName;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    async getOverViewInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          class: this.classId,
          room: this.classRoom,
        };

        const response = await ViService.viXPost(
          "/academic/overview_info",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          
          this.section = resultData.section;
          this.classDetails = resultData.class;
          this.students = resultData.students;
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    redirectRouteToViewStudent(){
      let userData = secureUI.sessionGet("user");

      if(userData.userDetails.profile.name == "School Admin"){
        this.$router.push(`/admission/view/${this.classId}/student/${this.classRoom}`);
      }else{
        this.$router.push(`/overview/${this.classId}/students/${this.classRoom}`);

      }
      // class: this.classId,
      //     room: this.classRoom,
    },
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getSubjectName(id) {
      let data = this.subjectList.find(x => x._id == id)
      if(data){
        return data.subjectName;
      }else{
        return '';
      }
     
    },

   
    
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeGradingScalePopUp() {
      this.$bvModal.hide("gradingScaleForOthers");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("curriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("curriculumPopUp");
    },
    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
   
    closeAttendanceGradingScalePopUp() {
      this.$bvModal.hide("attendanceGradingScale");
    },
    closeGradeSettingPopUp() {
      this.$bvModal.hide("gradeSetting");
    },
  },
  components: {
    FullCalendar,
  },
};
</script>
